
.question .form-text-field > input {
    width: 100%;
    box-sizing: border-box;
}

.question {
    display: block;
    margin-top: 1em;
    background-color: var(--subtle-detail-color);
    color: var(--subtle-detail-color-contrast);
    padding: 1em;
    border-radius: 1em;
    font-size: larger;

}

.question svg {
    width: 100%;
    height: 100%;
}

.question a {
    pointer-events: none;
    text-decoration: none;
    color: var(--subtle-detail-color-contrast)
}

.question-text {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.question-text img {
    max-width: 100%;
}

.question-subtext {
    font-size: medium;
    font-weight: normal;
}

.question-option-with-border {
    border: 2px solid lightgray;
    border-radius: 0.5em;
    display: block;
    width: 100%;
    margin: 5px;
    box-sizing: border-box;
    padding: 0.5em;
}

input:checked + label .question-option-with-border {
    border: 2px solid var(--subtle-detail-color-contrast);
}

.login-button-friendly {
    display: inline-block;
    background-color: var(--catch-detail-color);
    color: var(--catch-detail-color-contrast);
    border: solid var(--catch-detail-color-contrast) 2px;
    padding: 0.2em 0.6em;
    font-size: large;
    font-weight: bold;
    border-radius: 1.5em;
    box-sizing: border-box;
    width: 100%;
}


